<template>
  <div class="nav">
    <el-aside width="250px">
      <!--  v-bind:style="{ minHeight: Height + 'px' }" -->
      <el-menu
        :default-active="keys"
        class="el-menu-vertical-demo"
        @select="handleSelect"
      >
        <el-menu-item index="1" style="margin-top: 20px">
          <div class="aside_item">
            <!-- <i class="el-icon-search"></i> -->

            <span slot="title">上传文件</span>
          </div>
        </el-menu-item>
        <el-menu-item index="2">
          <div class="aside_item">
            <!-- <i class="el-icon-document-checked"></i> -->
            <span slot="title"> 查看报告</span>
            
          </div>
        </el-menu-item>
        
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
import { judge_user, article_update } from "@/Api/unquote";
export default {
  data() {
    return {
      Height: 0,
      // openeds: ['1','2','3'],
      keys: "",
    };
  },
  created() {
    // this.unreads();
    // 
    let path = this.$route.path;
    if (path == "/writefulUpload") {
      this.keys = "1";
    }  else {
      this.keys = "2";
    }
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 140; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 140;
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      //   console.log(key, keyPath);
      //   console.log(this.keys)
    },
    handleClose(key, keyPath) {
      //   console.log(key, keyPath);
      //   console.log(this.keys)
    },
    handleSelect(key) {
      //   console.log(key);
      this.keys = key;
      // window.sessionStorage.setItem("webNav", this.keys);
      let path = this.$route.path;
      if (this.keys == "1" && path !== "/writefulUpload") {
        this.$router.push({
          path: "/writefulUpload",
        });
      } else if (this.keys == "2" && path !== "/writefulList") {
        this.$router.push({
          path: "/writefulList",
        });
      } 
    },
    
  },
};
</script>

<style   lang="less" scoped>
.nav {
  background-color: #fff;
  .el-aside {
    // height: 100vh;

    color: #333;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    // height: 100%;
    background-color: #e9eef3;
    color: #333;
    // text-align: center;
    // line-height: 160px;
  }
  .el-menu-item {
    padding: 5px 50px !important;
    background-color: #fff;
    margin-bottom: 5px;
  }
  .aside_item {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    span {
      color: #b1aec8;
      font-size: 15px;
    }
  }
  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
  .el-menu {
    background-color: #fff;
  }
}
.is-active div {
  background-color: #B14A75;
  span {
    color: #fff !important;
  }
  i {
    color: #fff !important;
  }
}
.el-menu-item {
  padding: 5px 50px !important;
  background-color: #fff;
  margin-bottom: 5px;
}
// .aside_item {
//   width: 100%;
//   height: 50px;
//   line-height: 50px;
//   border-radius: 5px;
//   span {
//     color: #b1aec8;
//     font-size: 15px;
//   }
// }
.item {
  line-height: 30px;
  /deep/ .el-badge__content.is-fixed {
    position: absolute;
    top: 5px;
    right: 13px;
    transform: translateY(-50%) translateX(100%);
  }
}
</style>